<template>
  <b-modal
    id="addRole"
    :title="t('Add Role')"
    hide-footer
    size="md"
  >
    <validation-observer ref="addRole">
      <b-form
        autocomplete="off"
        @submit.prevent="submitAddRole"
      >
        <!-- Role Name -->
        <b-form-group
          :label="t('Role Name')"
          label-for="vi-role"
          class="required"
        >
          <validation-provider
            #default="{ errors }"
            class="input-group my-1"
            name="Role Name"
            vid="vi-role"
            rules="required"
          >
            <b-form-input
              id="vi-role"
              v-model="form.role_name"
              autocomplete="off"
              :class="errors[0] ? 'border-red' : ''"
              :placeholder="t('Role')"
              maxlength="50"
            />
          </validation-provider>
        </b-form-group>

        <!-- Group Code -->

        <b-form-group
          :label="t('Group Code')"
          label-for="vi-group-code"
          class="required"
        >
          <validation-provider
            #default="{ errors }"
            class="input-group my-1"
            name="Group Code"
            vid="vi-group-code"
            rules="required"
          >
            <b-form-input
              id="vi-group-code"
              v-model="form.group_code"
              autocomplete="off"
              :class="errors[0] ? 'border-red' : ''"
              :placeholder="t('Group Code')"
              maxlength="10"
            />
          </validation-provider>
        </b-form-group>

        <!-- Status -->
        <b-form-group
          :label="t('Status')"
          label-for="vi-status"
          class="required"
        >
          <validation-provider
            #default="{ errors }"
            class="input-group my-1"
            name="Status"
            vid="vi-status"
            rules="required"
          >
            <v-select
              v-model="form.status"
              class="p-0 border-0 form-control"
              :class="errors[0] ? 'border-red-vselect' : ''"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :reduce="status => status.value"
              placeholder="Select Status"
              :options="statusList"
            />
          </validation-provider>
        </b-form-group>

        <b-col
          cols="12"
          class="text-center pt-2"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            {{ t('Submit') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="$bvModal.hide('addRole')"
          >
            {{ t('Cancel') }}
          </b-button>
        </b-col>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useUtils } from '@core/libs/i18n'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import {
  failedToast, hideLoader, showLoader, status, successToast,
} from '@/utils/common'
import apis from '@/apis'
import store from '@/store'
import constants from '@/constants'

export default {
  name: 'AddRole',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: ['role_id'],
  data() {
    return {
      required,
      statusList: status,
      form: {
        role_id: '',
        role_name: '',
        group_code: '',
        status: '',
      },
    }
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  watch: {
    role_id: {
      deep: true,
      handler(val) {
        this.getRoleById(val)
      },
    },
  },
  mounted() {
    if (this.role_id) {
      this.getRoleById(this.role_id)
    }
    this.$root.$on('bv::modal::shown', (a, componentId) => {
      // eslint-disable-next-line eqeqeq
      if (componentId == 'addRole') {
        if (this.role_id) {
          this.getRoleById(this.role_id)
        }
      }
    })
    this.$root.$on('bv::modal::hidden', (a, componentId) => {
      // eslint-disable-next-line eqeqeq
      if (componentId == 'addRole') {
        this.form = {
          role_id: '',
          role_name: '',
          group_code: '',
          status: '',
        }
      }
    })
  },
  methods: {
    submitAddRole() {
      this.$refs.addRole.validate().then(success => {
        if (success) {
          this.saveRole()
        }
      })
    },
    async saveRole() {
      showLoader()
      await store.dispatch('rolesStore/roleActions', { url: apis.COMPANY_ROLE_SAVE, data: this.form }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          successToast(response.data.msg)
          hideLoader()
          this.$bvModal.hide('addRole')
          this.$root.$emit('refreshRoles')
        }
        hideLoader()
      }).catch(e => {
        hideLoader()
        failedToast(e.msg)
      })
    },
    getRoleById(id) {
      store.dispatch('rolesStore/roleActions', { url: apis.COMPANY_ROLE_BY_ID, data: { role_id: id } }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.form.role_id = id
          this.form.role_name = response.data.data.group_desc
          this.form.group_code = response.data.data.group_code
          this.form.status = response.data.data.status
        }
        hideLoader()
      }).catch(() => {
        hideLoader()
      })
    },
  },
}
</script>

<style>

</style>
