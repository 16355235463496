<template>

  <div>
    <div
      class="d-flex justify-content-end"
      :class="checkPermissions(constants.ADD_ROLE) ? '' : 'mb-2' "
    >
      <b-button
        v-if="checkPermissions(constants.ADD_ROLE)"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="button"
        variant="primary"
        class="mb-1"
        @click="$bvModal.show('addRole')"
      >
        {{ t('Add Role') }}
      </b-button>
    </div>
    <b-card>
      <b-table
        sticky-header
        responsive="true"
        :items="items.data"
        :fields="userFields"
        @row-contextmenu="contextmenu"
        @sort-changed="sortChanged"
      >
        <template #cell(created_at)="data">
          {{ dateRegFormat(data.item.created_at) }}
        </template>
        <template #cell(status)="data">
          <span
            v-if="data.item.status === constants.USER_STATUS_ACTIVE"
            class="text-success cursor-pointer"
            @click="changeStatus(data.item.status, data.item.encode_group_id )"
          >Active</span>
          <span
            v-if="data.item.status === constants.USER_STATUS_INACTIVE"
            class="text-danger cursor-pointer"
            @click="changeStatus(data.item.status, data.item.encode_group_id )"
          >In-Active</span>
        </template>

        <template
          v-if="items.total == 0"
          #table-caption
        >
          <div
            class="text-center"
          >
            {{ t('No Record Found') }}
          </div>
        </template>
      </b-table>
      <b-row v-if="items.total">
        <b-col
          cols="4"
          class="d-flex"
        >
          <div class="align-self-center pr-2">
            {{ t('Total Entries') + " " + items.total }}
          </div>
          <b-form-group class="align-self-center mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ t('Per page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="form.size"
              size="sm"
              :options="tableFilter.pageOptions"
              class="w-70"
              @change="getRolesList"
            />
          </b-form-group>

        </b-col>
        <b-col cols="8">
          <pagination
            :data="items"
            :limit="2"
            class="pt-1 justify-content-end"
            @pagination-change-page="getResults"
          />
        </b-col>

      </b-row>

    </b-card>
    <vue-context
      ref="roleListContext"
    >
      <li
        v-for="data in menuData"
        :key="data.text"
      >
        <b-link
          v-if="data.id == 1 && checkPermissions(constants.ADD_ROLE)"
          class="d-flex align-items-center"
          @click="optionClicked(data.id)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
        <b-link
          v-else-if="data.id !== 1 && rowDataContext.status == 'A'"
          class="d-flex align-items-center"
          @click="optionClicked(data.id)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>

    <add-role :role_id="idContext" />
  </div>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import Ripple from 'vue-ripple-directive'
import VueContext from 'vue-context'
import AddRole from './AddRole.vue'
import store from '@/store'
import apis from '@/apis'
import {
  dateRegFormat, failedToast, hideLoader, showLoader, successToast, tableFilter,
} from '@/utils/common'
import constants from '@/constants'
import axios from '@/utils/axiosCompany'
import checkPermission from '@/utils/checkPermissons'
// import checkPermission from '@/utils/utils'

export default {
  name: 'RoleList',
  components: {
    VueContext,
    AddRole,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tableFilter,
      constants,
      idContext: '',
      rowDataContext: [],
      items: [],
      form: {
        params: {
        },
        sortBy: 'group_id',
        sortOrder: 'desc',
        size: '10',
        pageNumber: '',
      },
    }
  },
  computed: {
    userFields() {
      return [
        { key: 'group_id', label: this.$i18n.t('Id'), sortable: true },
        { key: 'group_desc', label: this.$i18n.t('Title'), sortable: true },
        { key: 'status', label: this.$i18n.t('Status'), sortable: true },
        { key: 'created_at', label: this.$i18n.t('Created At'), sortable: true },
      ]
    },
    menuData() {
      return [
        { id: 1, icon: 'EditIcon', text: this.$i18n.t('Edit Role') },
        { id: 2, icon: 'LayersIcon', text: this.$i18n.t('Manage Rights') },
      ]
    },
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  mounted() {
    this.getRolesList()
    this.$root.$on('refreshRoles', () => {
      this.getRolesList()
    })
  },
  methods: {
    checkPermissions(code) {
      return checkPermission(code)
    },
    dateRegFormat(date) {
      return dateRegFormat(date)
    },
    sortChanged() {

    },
    async getResults(page = 1) {
      showLoader()
      // eslint-disable-next-line no-undef
      await axios.post(`/user-role/list?page=${page}`, this.form, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
        .then(response => {
          this.items = response.data.data
          hideLoader()
        })
      hideLoader()
    },
    changeStatus(status, id) {
      // eslint-disable-next-line eqeqeq
      if (status == 'A') {
        this.$swal({
          title: this.$i18n.t('Are you sure ?'),
          text: this.$i18n.t('You want to Deactivate this Role!'),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$i18n.t('Cancel'),
          confirmButtonText: this.$i18n.t('Yes'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.changeRoleStatus(id, 'I')
          }
        })
      } else {
        this.$swal({
          title: this.$i18n.t('Are you sure ?'),
          text: this.$i18n.t('You want to Activate this Role!'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('Yes'),
          cancelButtonText: this.$i18n.t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.changeRoleStatus(id, 'A')
          }
        })
      }
    },
    async changeRoleStatus(id, status) {
      showLoader()
      await store.dispatch('rolesStore/roleActions', { url: apis.COMPANY_ROLE_CHANGE_STATUS, data: { role_id: id, status } }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          hideLoader()
          successToast(response.data.msg)
          this.getRolesList()
        }
        hideLoader()
      }).catch(e => {
        failedToast(e.msg)
        hideLoader()
      })
    },
    async getRolesList() {
      showLoader()
      await store.dispatch('rolesStore/roleActions', { url: apis.COMPANY_ROLES_LIST, data: this.form }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.items = response.data.data
          hideLoader()
        }
        hideLoader()
      }).catch(() => {
        hideLoader()
      })
    },
    contextmenu(item, index, event) {
      event.preventDefault()
      this.idContext = item.encode_group_id
      this.rowDataContext = item
      this.$refs.roleListContext.open(event, item)
    },
    optionClicked(id) {
      if (id === 1) {
        this.$bvModal.show('addRole')
      } else if (id === 2) {
        this.$router.push({ name: 'company-users/show-rights', params: { role_id: this.rowDataContext.group_id } })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-context.scss';
</style>
